/* JournalMenuSubMenu.css */
.menu-submenu-item:hover .arrow-icon {
    opacity: 1;
    transform: translateX(8px);
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.menu-submenu-item .arrow-icon {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    transform: translateX(-8px);
    transition: transform 0.2s ease-in-out;
}
