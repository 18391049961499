#root {
    /* height: 100%; */
}

.react-virtualized-menu-placeholder {
    margin-top: 12px;
    color: #9a9a9a;
    text-align: center;
}
.react-virtualized-list-wrapper li {
    list-style: none;
}

.chakra-popover__popper {
    z-index: 9999 !important;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 6px;
    border-radius: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: #899878;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

#create::-webkit-scrollbar {
    display: none;
    scroll-behavior: smooth;
}
/* Works on Chrome, Edge, and Safari */
/* ::-webkit-scrollbar {
    width: 4px;
    scroll-behavior: smooth;
    display: none;
}

::-webkit-scrollbar-thumb {
    background-color: var(--chakra-colors-secondary);
    border-radius: 20px;
    border: 3px solid var(--chakra-colors-primary);
    height: 2px;
} */

.se-toolbar,
.sun-editor-common div,
.sun-editor,
.sun-editor .se-btn-module-border {
    border: none !important;
    outline: none !important;
}

.tox-tinymce::-webkit-scrollbar {
    display: none !important;
    scroll-behavior: smooth;
}

.tox-tinymce--disabled {
    border: none !important;
}

.tox .tox-sidebar-wrap {
    min-height: 400px !important;
}

.success-progress > div {
    background-color: #899878 !important;
}

label input {
    height: 0;
    width: 0;
    opacity: 0;
}

.up {
    position: absolute;
    background: red;
}

.fileInput,
#fileInput,
#fileInputt,
#fileUploader,
#imageUploader,
#attachmentUploader,
#profileImageUploader {
    display: none;
}
/* input[type=file]{
    display:none
} */

.Rejected {
    background-color: red;
}

.manuscriptCoverImage {
    height: 16rem;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    width: 100%;
}
.select::placeholder {
    font-size: 10px;
}

.countryDropdown {
    background-color: #e4e6c3;
    padding: 10px;
    border-radius: 5px;
}

@media screen and (max-width: 485px) {
    .manuscriptCoverImage {
        background-size: contain;
        margin-top: 2rem;
    }
}

.coverImage {
    min-height: 18rem;
    max-height: 20rem;
    min-width: 15rem;
    max-width: 18rem;
    display: flex;
    justify-content: center;
}

.coverImage img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 1rem;
}

.cover {
    border-radius: 1rem;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.uploadIcon {
    width: 0.5rem;
    height: 0.5rem;
}

.issueCoverImage {
    min-height: 10rem;
    max-height: 12rem;
    min-width: 15rem;
    max-width: 18rem;
    display: flex;
    justify-content: center;
}

.issue {
    min-width: 15rem;
    /* width */
    max-width: 15rem;
    /* height:12rem; */
    max-height: 13rem;
    min-height: 13rem;
}

.issue img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    border-radius: 1rem;
}

.issueCoverImage img {
    min-height: 100%;
    min-width: 100%;
    background: red;
    object-fit: cover;

    border-radius: 1rem;
}

.imageViewer {
    height: 90vh;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageViewer img {
    height: 95%;
    width: 100%;
    object-fit: contain;
}

@media screen and (max-width: 485px) {
    .imageViewer {
        width: 95%;
    }
    .imageViewer img {
        scale: 1.2;
    }

    .react-calendar {
        padding: 0 !important;
    }
    .coverImage {
        width: 100%;

        display: flex;
        justify-content: flex-start;

        height: 25rem;
    }

    .coverImage img {
        object-fit: fit;
        width: 100%;
        height: 100%;
    }

    .issue {
        min-width: 15rem;
        /* width */
        max-width: 15rem;
        /* height:12rem; */
        max-height: max-content;
        min-height: 13rem;
    }

    .issue img {
        width: 100%;
        height: 15rem;
        /* object-position:center; */
        object-fit: cover;
        border-radius: 1rem;
    }
}

#pdf-renderer {
    background-color: #d9d9d9;
}

@media (max-width: 350px) {
    .contact-img {
        margin-left: -10px;
    }
}

.react-calendar {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 3% 0 !important;
    max-width: 100%;
    background: white;
    height: 20rem;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}
.react-calendar--doubleView {
    width: 1000px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;

    color: #899878 !important;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}
.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #899878;
    color: white;
}

textarea {
    resize: none;
    background: transparent;
    outline: none;
    width: 100%;
    padding-left: 5px;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    color: #899878 !important;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
    color: black !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    background: #899878 !important;
    border-radius: 10px !important;
    color: white;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #e4e6c3 !important;
    border-radius: 10px !important;
}
.react-calendar__tile--hasActive {
    background: #e4e6c3 !important;
    border-radius: 10px !important;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #e4e6c3 !important;
    border-radius: 10px !important;
}
.react-calendar__tile--active {
    background: #e4e6c3 !important;
    color: black;
    background: #e4e6c3 !important;
    border-radius: 10px !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #e4e6c3 !important;
    border-radius: 10% !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

/* Tooltip z-index fix and custom styles */
.css-3l0anw {
    z-index: 9999 !important;
    font-size: 1em;
}

.blog-iframe-wrapper iframe {
    height: 300px;
    width: 100%;
    object-fit: cover;
}
.single-blog-iframe-wrapper iframe {
    width: 100%;
    max-height: 900px;
    object-fit: contain;
    height: 100%;
    height: 200px;
}
.podVid-iframe-wrapper iframe {
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
    height: 100%;
    height: 500px;
}
.single-pod-iframe-wrapper iframe {
    border-radius: 15px;
    height: 200px;
    object-position: center;
    object-fit: cover;
    /* display: none; */
}

.manuscript-message-modal-message-input .tox-tinymce {
    border: none !important;
}

#progress {
    position: fixed;
    bottom: 20px;
    right: 10px;
    height: 50px;
    width: 50px;
    display: none;
    place-items: center;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
#progress-value {
    display: block;
    height: calc(100% - 5px);
    width: calc(100% - 5px);
    background-color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 20px;
    color: #001a2e;
}

.pa {
    padding-bottom: 2000px !important;
}

#journalImageContainer {
    border-radius: 2rem !important;
}

#journalImageContainer img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px !important;
}

.sticky-element {
    background-color: green;
    border: 2px solid #4caf50;
    padding: 10px;
    color: black;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.journalScroll::-webkit-scrollbar {
    display: block;
    scroll-behavior: smooth;
}

.documentViewer::-webkit-scrollbar {
    display: block;
    scroll-behavior: smooth;
    width: 4px;
}

.documentViewer::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 3px solid var(--chakra-colors-primary);
    height: 2px;
}

#pdf-renderer::-webkit-scrollbar {
    display: block;
    scroll-behavior: smooth;
    width: 4px;
    cursor: pointer;
}

#pdf-renderer,
.documentViewer {
    cursor: pointer;
}

#my-tinymce-editor {
    width: 100% !important;
    height: 300px !important;
    border: 5px solid red !important;
}

#affiliateImageContainer img {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.Text {
    transition:
        color 0.8s,
        text-decoration 1s;
}

.Text:hover {
    color: #98a589;
}

#web-container h1 {
    padding: 3rem 0 1rem;
    font-weight: 700;
    font-size: 1.5rem;
    max-width: 100%;
}
#web-container h2 {
    padding: 0.2rem 0 0.5rem;
    font-weight: 500;
    font-size: 1.1rem;
    max-width: 100%;
}

#web-container h3 {
    padding: 0.2rem 0 0.5rem;
    font-weight: 500;
    font-size: 1.1rem;
    max-width: 100%;
}

#web-container h4 {
    padding: 0.2rem 0 0.5rem;
    font-weight: 500;
    max-width: 100%;

    font-size: 1.1rem;
}

#web-container h5 {
    padding: 0.2rem 0 0.5rem;
    font-weight: 500;
    font-size: 1.1rem;
    max-width: 100%;
}

#web-container h6 {
    padding: 1rem 0 1rem;
    font-weight: 500;
    font-size: 1.1rem;
    max-width: 100%;
}

#web-container h3 {
    padding: 0.5rem 0 0.5rem;
    max-width: 100%;
}

#web-container li {
    margin: 0.5rem 0;
    max-width: 100%;
}

#web-container p {
    /* padding-bottom: 0.5rem; */
    color: #282828;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 400;
    margin: 0 0 16px;
    max-width: 100%;
}

#web-container img {
    margin: 2rem auto;

    /* background: black; */
    max-width: 100%;
    object-fit: contain;
    max-width: 100%;
}
.option {
    background: #e4e6c3;
    color: black;
    padding: 0.5rem 0;
}

@media screen and (max-width: 485px) {
    textarea {
        resize: none;
        background: transparent;
        outline: none;
        width: 80%;
        height: max-content;
        maxheight: 5rem;
        padding-left: 5px;
    }

    #web-container table {
        font-size: 10px !important;
        overflow: auto !important;
        max-width: 100%;
    }
    #web-container h1 {
        padding: 3rem 0 1rem;
        font-weight: 700;
        font-size: 1.5rem;
        max-width: 90%;
    }
    #web-container h2 {
        padding: 0.2rem 0 0.5rem;
        font-weight: 500;
        font-size: 1.1rem;
        max-width: 90%;
    }

    #web-container h3 {
        padding: 0.2rem 0 0.5rem;
        font-weight: 500;
        font-size: 1.1rem;
        max-width: 90%;
    }

    #web-container h4 {
        padding: 0.2rem 0 0.5rem;
        font-weight: 500;
        max-width: 90%;
        font-size: clamp(1.1rem, 2.5vw, 2rem);
    }

    #web-container h5 {
        padding: 0.2rem 0 0.5rem;
        font-weight: 500;

        font-size: clamp(1.1rem, 2.5vw, 2rem);
        max-width: 90%;
    }

    #web-container h6 {
        padding: 1rem 0 1rem;
        font-weight: 500;
        font-size: 1.1rem;
        max-width: 90%;
    }

    #web-container h3 {
        padding: 0.5rem 0 0.5rem;
        max-width: 90%;
    }

    #web-container li {
        margin: 0.5rem 0;
        max-width: 100%;
    }

    #web-container p {
        /* padding-bottom: 0.5rem; */
        color: #282828;

        line-height: 1.375rem;
        font-weight: 400;
        margin: 0 0 16px;
        max-width: 100%;
    }

    #web-container p {
        font-size: clamp(0.9rem, 2.5vw, 2rem);
    }

    #web-container img {
        margin: 2rem auto;

        /* background: black; */

        object-fit: contain;
        max-width: 90%;
    }

    @media screen and (max-width: 485px) {
        #web-container table {
            font-size: 10px !important;
            overflow-x: auto !important;
            max-width: 90%;
            display: block;
            margin: 1.5rem 0;
        }

        #web-container table th {
            /* border: 2px solid red; */
            min-width: max-content;
        }
    }

    #web-container > blockquote u {
        color: #1976d6;
        max-width: 90%;
    }
    #web-container > blockquote p {
        color: black;
        margin: 0 0 20px !important;
        font-weight: 400;
        max-width: 90%;
    }
}

#web-container > blockquote u {
    color: #1976d6;
    max-width: 100%;
}
#web-container > blockquote p {
    color: black;
    margin: 0 0 20px !important;
    font-weight: 400;
    max-width: 100%;
}

/* ------ */
.stdropdown-container {
    width: 100%;
    position: relative;
    background-color: #e4e6c3;
    border-radius: 5px;
}

.stdropdown-menu::-webkit-scrollbar {
    width: 2px;
}

.stdropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.stdropdown-menu::-webkit-scrollbar-thumb {
    background: #888;
}

.stdropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.stdropdown-input {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    background-color: #e4e6c3;
    border-radius: 5px;
}

.stdropdown-menu {
    position: absolute;
    transform: translateY(4px);
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
    max-height: 150px;
    background-color: #e4e6c3;
    z-index: 99;
}

.stdropdown-item {
    padding: 10px 5px;
    cursor: pointer;
    border-bottom: 0.5px solid #d7d9b8;
}

.stdropdown-item:hover {
    background-color: #899878;
    color: white;
}

.stdropdown-item.selected {
    background-color: #899872;
    color: #fff;
}

.stdropdown-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.stdropdown-tag-item {
    background-color: #ddd;
    padding: 2px 4px;
    border-radius: 2px;
    display: flex;
    align-items: center;
}

.stdropdown-tag-close {
    display: flex;
    align-items: center;
}

.stsearch-box {
    padding: 5px;
    background-color: transparent;
}

.stsearch-box input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: none;
    outline: none;
    background: transparent;
}

.aaa__control {
    background: red;
}
